:root {
  --base-color: #fff;
  --border-color: #ccc;
}

* {
  font-family: Arial, Helvetica, sans-serif;
}

body {
  /* background-color: #f1e9c0; */
  background-color: #fc0d19fa;
}

nav {
  text-align: center;
}

.MuiButton-label {
  font-weight: bold;
}

h1 {
  text-align: center;
  text-transform: uppercase;
}

h2 {
  background-color: #111;
  color: var(--base-color);
  margin-top: 0;
  margin-bottom: 0;
  padding: 0.65rem 0.83rem;
}

h3 {
  background-color: #111;
  color: var(--base-color);
  border-bottom: 1px solid #ccc;
  display: inline-block;
  padding: 0.415rem 0.83rem;
  margin-top: 0.5rem;
  margin-bottom: 0.25em;
}

h4 {
  /* text-decoration: underline; */
  /* background-color: #111; */
  /* color: #eee; */
  color: #111;
  /* font-weight: 500; */
  border-bottom: 1px solid #111;
  margin-top: 0;
  margin-bottom: 0;
  /* margin-left: 0.54rem; */
  margin-left: 0.83rem;
  /* padding: 0.4rem 0.4rem 0.4rem 0.29rem; */
  padding: 0.4rem 0.4rem 0.4rem 0rem;

  /* margin-right: 0.83rem; */
}

ul {
  list-style: none;
  padding: 0;
  padding-left: 0.83rem;
  padding-right: 0.83rem;
  line-height: 1.5;
  margin-top: 0.5em;
}

.container-masonry {
  column-count: 1;
  column-gap: 1em;
  margin: 1.5em 0;
  padding: 0;
  -moz-column-gap: 1.5em;
  -webkit-column-gap: 1.5em;
  column-gap: 1.5em;
  font-size: 0.85em;
}

.item-masonry {
  background-color: #eee;
  border: 1px solid #111;
  display: inline-block;
  margin: 0 0 1em;
  width: 100%;
  padding: 0;
  margin: 0 0 1.5em;
  width: 100%;
  transition: all 1s ease inherit;
  -webkit-transition: all 1s ease inherit;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* box-shadow: 2px 2px 4px 0 #ccc; */
}

@media only screen and (min-width: 400px) {
  .container-masonry {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media only screen and (min-width: 700px) {
  .container-masonry {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

@media only screen and (min-width: 900px) {
  .container-masonry {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
  }
}

/* @media only screen and (min-width: 1100px) {
  .container-masonry {
      -moz-column-count: 5;
      -webkit-column-count: 5;
      column-count: 5;
  }
} */

@media only screen and (min-width: 1280px) {
  .wrapper {
    width: 1260px;
  }
}

.locationLinks {
  /* text-align: center; */
  background-color: #111;
  display: flex;
  justify-content: space-around;
}

.locationLinks a {
  color: #eee;
  font-size: 1.25rem;
  padding: 10px 0 6px;
}

.locationLinks path {
  stroke: #eee;
}
